import { isEmpty } from "lodash";
import pickBy from "lodash/pickBy";
import { DataTableSortStatus } from "mantine-datatable";

import {
  LimitDetailFilter,
  LimitDetailOrderBy,
} from "../../../../graphql/generated.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { LimitFiltersValue } from "./types.ts";

export const getLimitApiFilter = (
  value: LimitFiltersValue
): LimitDetailFilter => {
  return pickBy({
    limitType: value.limitType && {
      equals: value.limitType,
    },
    status: value.limitStatus && { equals: value.limitStatus },
  });
};

export const getLimitApiSort = (
  tableSort: DataTableSortStatus
): LimitDetailOrderBy => {
  const { tenorApproved, pricingApproved, ...apiSort } =
    getApiOrderBy(tableSort);
  const limitRequest = pickBy({
    tenorApproved,
    pricingApproved,
  });
  return pickBy({
    ...apiSort,
    limitRequest: isEmpty(limitRequest) ? undefined : limitRequest,
  });
};
