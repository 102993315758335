import { Flex } from "@mantine/core";

import Layout from "../../components/Layout/Layout.tsx";
import Title from "../../components/Title/Title.tsx";
import CreditLimitFunnelTable from "./CreditLimitFunnel/CreditLimitFunnelTable.tsx";
import LimitUtilizationTable from "./LimitUtilization/LimitUtilizationTable.tsx";

const RiskFunnelDashboardPage = () => {
  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Risk Funnel Dashboard</Title>
        <CreditLimitFunnelTable />
        <LimitUtilizationTable />
      </Flex>
    </Layout>
  );
};

export default RiskFunnelDashboardPage;
