import { Flex } from "@mantine/core";

import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import NewCURecommendationTable from "./NewCURecommendation/NewCURecommendationTable.tsx";
import OngoingRRTable from "./OngoingRR/OngoingRRTable.tsx";

const RiskReviewPage = () => {
  const [{ data: companiesData }] = useCompanyOptions();
  const companies = companiesData?.customers.data;

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Risk Review</Title>
        <NewCURecommendationTable companies={companies} />
        <OngoingRRTable companies={companies} />
      </Flex>
    </Layout>
  );
};

export default RiskReviewPage;
