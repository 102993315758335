import { MantineTheme } from "@mantine/core";
import { rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const goldenFilled = (theme: MantineTheme) => ({
  bar: {
    background: `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)!important`,
  },
});

const progressSlider = () => ({
  track: {
    height: rem(12),
    borderRadius: rem(2),

    "&::before": {
      borderRadius: rem(2),
    },
  },
  bar: {
    background: "linear-gradient(180deg, #8DABDA 0%, #5C7BAB 100%)",
    borderRadius: rem(2),
  },
});

export default function sliderTheme(): ThemeComponent {
  return {
    variants: {
      filledGolden: goldenFilled,
    },
    styles: () => progressSlider(),
  };
}
