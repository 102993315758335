import { Select } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import React, { useCallback, useMemo } from "react";

import { useCurrentUserContext } from "../../../../components/Auth/CurrentUserProvider.tsx";
import { TableData, TableRow } from "../../../../components/Table/types";
import {
  RolePermission,
  UpdateRMonAssignedInput,
} from "../../../../graphql/generated.ts";
import { LimitRequest } from "../../../../types/limitRequest.ts";
import { UserOption } from "../../../../types/user.ts";
import { toLocalDate } from "../../../../utils/date";
import { toLocalNumber, toPrice } from "../../../../utils/number";
import { toHumanReadable } from "../../../../utils/string";
import {
  getUserDisplayName,
  getUserOptions,
  hasPermission,
} from "../../../../utils/user.ts";

interface Props {
  data: LimitRequest[] | null | undefined;
  assignees: UserOption[] | null | undefined;
  onEditRMonAssigned: (id: number, input: UpdateRMonAssignedInput) => void;
}

export const useNewRRAnalysisTableData = ({
  data,
  assignees,
  onEditRMonAssigned,
}: Props): TableData => {
  const { user } = useCurrentUserContext();

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditRiskMonitoring
  );

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "rrResultDate",
      title: "RR Result Date",
      sortable: true,
    },
    {
      accessor: "limitName",
      title: "Limit Name",
      sortable: true,
      width: 300,
    },
    {
      accessor: "companyName",
      title: "Company Name",
      sortable: true,
      width: 300,
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: "Sales Team",
      sortable: true,
    },

    {
      accessor: "limitRecommendation",
      title: "RR Limit Recommendation",
      sortable: true,
    },
    {
      accessor: "tenorRecommendation",
      title: "RR Tenor Recommendation",
      sortable: true,
    },
    {
      accessor: "cuInsuranceLimit",
      title: "Insurance Limit",
      sortable: true,
    },
    {
      accessor: "limitType",
      title: "Limit Type",
      sortable: true,
    },
    {
      accessor: "cuAssignedName",
      title: "CU Assigned",
      sortable: true,
      width: 250,
    },
    {
      accessor: "rrAssignedName",
      title: "RU Assigned",
      sortable: true,
      width: 250,
    },
    {
      accessor: "rMonAssignedName",
      title: "RMon Assigned",
      sortable: true,
      width: 250,
    },
    {
      accessor: "requestStatus",
      title: "Request Status",
    },
  ];

  const handleOnClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    //  stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();
  }, []);

  const handleEditRMonAssigned = useCallback(
    (id: number, rMonAssignedId: number) => {
      onEditRMonAssigned(id, { rMonAssignedId, rMonAssignedDate: new Date() });
    },
    [onEditRMonAssigned]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        rrResultDate: toLocalDate(item.riskReview?.analysisResultDate),
        limitName: item.limitName,
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeamName: item.company.salesTeamName,
        limitRecommendation: toPrice(item.riskReview?.limitRecommendation),
        tenorRecommendation: toLocalNumber(
          item.riskReview?.tenorRecommendation
        ),
        cuInsuranceLimit: toPrice(
          item.creditUnderwriting?.insuranceLimitApproved
        ),
        limitType:
          item.limitRequestType && toHumanReadable(item.limitRequestType),
        cuAssignedName: item.cuAssigned && getUserDisplayName(item.cuAssigned),
        rrAssignedName: item.rrAssigned && getUserDisplayName(item.rrAssigned),
        rMonAssignedName: hasEditAccess ? (
          <Select
            data={getUserOptions(assignees)}
            value={item.rMonAssigned?.id.toString()}
            withinPortal
            zIndex={100}
            size="s"
            onChange={(value) => handleEditRMonAssigned(item.id, Number(value))}
            onClick={(event) => handleOnClick(event)}
          />
        ) : (
          getUserDisplayName(item.rMonAssigned)
        ),
        requestStatus: toHumanReadable(item.requestStatus),
      };
    });
  }, [data, hasEditAccess, assignees, handleEditRMonAssigned, handleOnClick]);

  return [columns, rows];
};
