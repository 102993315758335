import get from "lodash/get";
import isBoolean from "lodash/isBoolean";

export const stringToBoolean = (
  value: string | null | undefined,
  strict = false
): boolean | null => {
  const stringToBooleanMap = {
    true: true,
    false: false,
  };

  if (!value) {
    return strict ? null : false;
  }

  return get(stringToBooleanMap, value, null);
};

export const booleanToString = (
  value: boolean | null | undefined,
  mode: "display" | "formValue" = "display"
) => {
  if (!isBoolean(value)) {
    return null;
  }
  if (mode === "display") {
    return value ? "True" : "False";
  }
  if (mode === "formValue") {
    return value ? "true" : "false";
  }
};
