import { Select } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import React, { useCallback, useMemo } from "react";

import { useCurrentUserContext } from "../../../../components/Auth/CurrentUserProvider.tsx";
import { TableData, TableRow } from "../../../../components/Table/types";
import {
  RolePermission,
  UpdateRrAssignedInput,
} from "../../../../graphql/generated.ts";
import { LimitRequest } from "../../../../types/limitRequest.ts";
import { UserOption } from "../../../../types/user.ts";
import { toLocalDate } from "../../../../utils/date";
import { toLocalNumber, toPrice } from "../../../../utils/number";
import { toHumanReadable } from "../../../../utils/string";
import {
  getUserDisplayName,
  getUserOptions,
  hasPermission,
} from "../../../../utils/user.ts";

interface Props {
  assignees: UserOption[] | null | undefined;
  data: LimitRequest[] | null | undefined;
  onEditRRAssigned: (id: number, input: UpdateRrAssignedInput) => void;
}

export const useNewCURecommendationTableData = ({
  data,
  assignees,
  onEditRRAssigned,
}: Props): TableData => {
  const { user } = useCurrentUserContext();

  const hasEditAccess = hasPermission(user, RolePermission.CanEditRiskReview);

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "cuResultDate",
      title: "CU Result Date",
      sortable: true,
    },
    {
      accessor: "limitName",
      title: "Limit Name",
      sortable: true,
      width: 300,
    },
    {
      accessor: "companyName",
      title: "Company Name",
      sortable: true,
      width: 300,
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: "Sales Team",
      sortable: true,
    },

    {
      accessor: "cuLimitRecommendation",
      title: "CU Limit Recommendation",
      sortable: true,
    },
    {
      accessor: "cuTenorRecommendation",
      title: "CU Tenor Recommendation",
      sortable: true,
    },
    {
      accessor: "cuInsuranceLimit",
      title: "Insurance Limit",
      sortable: true,
    },
    {
      accessor: "limitType",
      title: "Limit Type",
      sortable: true,
    },
    {
      accessor: "cuAssignedName",
      title: "CU Assigned",
      sortable: true,
      width: 250,
    },
    {
      accessor: "rrAssignedName",
      title: "RR Assigned",
      sortable: true,
      width: 250,
    },
    {
      accessor: "requestStatus",
      title: "Request Status",
    },
  ];

  const handleOnClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    //  stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();
  }, []);

  const handleEditRRAssigned = useCallback(
    (id: number, rrAssignedId: number) => {
      onEditRRAssigned(id, { rrAssignedId, rrAssignedDate: new Date() });
    },
    [onEditRRAssigned]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        cuResultDate:
          item?.creditUnderwriting?.analysisResultDate &&
          toLocalDate(item.creditUnderwriting.analysisResultDate),
        limitName: item.limitName,
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeamName: item.company.salesTeamName,
        cuLimitRecommendation: toPrice(
          item?.creditUnderwriting?.limitRecommendation
        ),
        cuTenorRecommendation: toLocalNumber(
          item?.creditUnderwriting?.tenorRecommendation
        ),
        cuInsuranceLimit: toPrice(
          item?.creditUnderwriting?.insuranceLimitApproved
        ),
        limitType:
          item?.limitRequestType && toHumanReadable(item.limitRequestType),
        cuAssignedName: item?.cuAssigned && getUserDisplayName(item.cuAssigned),
        rrAssignedName: hasEditAccess ? (
          <Select
            data={getUserOptions(assignees)}
            value={item.rrAssigned?.id.toString()}
            withinPortal
            zIndex={100}
            size="s"
            onChange={(value) => handleEditRRAssigned(item.id, Number(value))}
            onClick={(event) => handleOnClick(event)}
          />
        ) : (
          getUserDisplayName(item.rrAssigned)
        ),
        requestStatus: toHumanReadable(item.requestStatus),
      };
    });
  }, [data, hasEditAccess, assignees, handleOnClick, handleEditRRAssigned]);

  return [columns, rows];
};
