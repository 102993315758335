import { Flex } from "@mantine/core";

import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { useCUAssignees } from "../../hooks/api/user/useCUAssignees.ts";
import NewLimitRequestedTable from "./NewLimitRequested/NewLimitRequestedTable.tsx";
import OngoingCUTable from "./OngoingCU/OngoingCUTable.tsx";

const CreditUnderwritingPage = () => {
  const [{ data: companiesData }] = useCompanyOptions();
  const companies = companiesData?.customers.data;

  const [{ data: assigneesData }] = useCUAssignees();
  const assignees = assigneesData?.users?.data;

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Credit Underwriting</Title>
        <NewLimitRequestedTable companies={companies} assignees={assignees} />
        <OngoingCUTable companies={companies} assignees={assignees} />
      </Flex>
    </Layout>
  );
};

export default CreditUnderwritingPage;
