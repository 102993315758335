import {
  Button,
  Flex,
  Input,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  SimpleGrid,
  Textarea,
} from "@mantine/core";
import { SelectItem } from "@mantine/core/lib/Select/types";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import omit from "lodash/omit";
import { useCallback } from "react";

import Dropzone from "../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../components/Icons/Calendar.tsx";
import {
  CreateCreditUnderwritingInput,
  CuAnalysisResult,
  CuInsuranceResult,
  CuResultCategory,
  FileResult,
} from "../../../graphql/generated.ts";
import {
  CreditUnderwriting,
  cuAnalysisResultOptions,
  cuCancelSubmissionOptions,
  cuInsuranceResultOptions,
  cuLoiResultCategoryOptions,
  cuResultCategoryOptions,
} from "../../../types/creditUnderwriting.ts";
import { LimitRequestDetail } from "../../../types/limitRequest.ts";
import { parseStringToNumber, priceFormatter } from "../../../utils/number.ts";

export type CreditUnderwritingModalSubmitValue = Omit<
  CreateCreditUnderwritingInput,
  "id" | "limitRequestId"
>;

interface Props {
  value: CreditUnderwriting | null | undefined;
  limitRequest:
    | Pick<LimitRequestDetail, "cuAssigned" | "cuAssignedDate">
    | null
    | undefined;
  assigneesOptions: SelectItem[];
  opened: boolean;
  onClose: () => void;
  onSubmit: (
    assignedId: number,
    assignedDate: Date,
    value: CreditUnderwritingModalSubmitValue
  ) => void;
}

const CreditUnderwritingModal = ({
  value,
  limitRequest,
  assigneesOptions,
  opened = false,
  onClose,
  onSubmit,
}: Props) => {
  const form = useForm<Partial<CreditUnderwriting>>({
    initialValues: {
      assignedDate: limitRequest?.cuAssignedDate
        ? new Date(limitRequest.cuAssignedDate)
        : undefined,
      assignedId: limitRequest?.cuAssigned?.id,
      clikResultReviewDate: value?.clikResultReviewDate
        ? new Date(value.clikResultReviewDate)
        : undefined,
      clikResultCategory: value?.clikResultCategory,
      loiResultDate: value?.loiResultDate
        ? new Date(value.loiResultDate)
        : undefined,
      loiResultFile: value?.loiResultFile,
      loiResultCategory: value?.loiResultCategory,
      insuranceRequestDate: value?.insuranceRequestDate
        ? new Date(value.insuranceRequestDate)
        : undefined,
      insuranceResultDate: value?.insuranceResultDate
        ? new Date(value.insuranceResultDate)
        : undefined,
      insuranceResult: value?.insuranceResult,
      insuranceLimitApproved:
        value?.insuranceLimitApproved && Number(value.insuranceLimitApproved),
      visitDate: value?.visitDate ? new Date(value.visitDate) : undefined,
      documentCollectionStartDate: value?.documentCollectionStartDate
        ? new Date(value.documentCollectionStartDate)
        : undefined,
      documentCompletedDate: value?.documentCompletedDate
        ? new Date(value.documentCompletedDate)
        : undefined,
      analysisResultDate: value?.analysisResultDate
        ? new Date(value.analysisResultDate)
        : undefined,
      analysisResult: value?.analysisResult,
      analysisFile: value?.analysisFile,
      limitRecommendation:
        value?.limitRecommendation && Number(value.limitRecommendation),
      tenorRecommendation:
        value?.tenorRecommendation && Number(value.tenorRecommendation),
      pricingRecommendation:
        value?.pricingRecommendation && Number(value.pricingRecommendation),
      cancelSubmissionReason: value?.cancelSubmissionReason,
      notes: value?.notes,
    },
    validate: {
      assignedDate: isNotEmpty("CU Assigned Date is required"),
      assignedId: isNotEmpty("CU Assigned is required"),
      clikResultReviewDate: isNotEmpty("CLIK Result Review Date is required"),
      clikResultCategory: isNotEmpty("CLIK Result Category is required"),
      loiResultDate: isNotEmpty("LOI Result Date is required"),
      loiResultCategory: isNotEmpty("LOI Result Category is required"),
      insuranceRequestDate: isNotEmpty("Insurance Request Date is required"),
      insuranceResultDate: isNotEmpty("Insurance Result Date is required"),
      insuranceResult: isNotEmpty("Insurance Result is required"),
      insuranceLimitApproved: isNotEmpty(
        "Insurance Limit Recommended is required"
      ),
      visitDate: isNotEmpty("Visit Date is required"),
      documentCollectionStartDate: isNotEmpty(
        "Document Collection Date is required"
      ),
      documentCompletedDate: isNotEmpty("Document Completed Date is required"),
      analysisResultDate: isNotEmpty("CU Analysis Result Date is required"),
      analysisResult: isNotEmpty("CU Analysis Result is required"),
      analysisFile: isNotEmpty("CU Analysis is required"),
      limitRecommendation: isNotEmpty("CU Limit Recommendation is required"),
      tenorRecommendation: isNotEmpty("CU Tenor Recommendation is required"),
      pricingRecommendation: isNotEmpty(
        "CU Pricing Recommendation is required"
      ),
      cancelSubmissionReason: (value, values) =>
        values.analysisResult === CuAnalysisResult.CancelSubmission
          ? isNotEmpty("CU Cancel Submission Reason is required")(value)
          : null,
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (
      !form.isValid() ||
      !form.values.assignedId ||
      !form.values.assignedDate
    ) {
      return;
    }
    onSubmit(
      form.values.assignedId,
      form.values.assignedDate,
      omit(
        {
          ...form.values,
          loiResultFile: form.values.loiResultFile?.filePath ?? null,
          analysisFile: form.values.analysisFile?.filePath,
        },
        "assignedId",
        "assignedDate"
      ) as CreditUnderwritingModalSubmitValue
    );
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleFileUploaded = (
    name: keyof CreditUnderwriting,
    files: FileResult[]
  ) => {
    if (name === "loiResultFile") {
      form.setValues({ loiResultFile: files[0] });
    }
    if (name === "analysisFile") {
      form.setValues({ analysisFile: files[0] });
    }
  };

  const handleRemoveFile = (name: keyof CreditUnderwriting) => {
    if (name === "loiResultFile") {
      form.setValues({ loiResultFile: undefined });
    }
    if (name === "analysisFile") {
      form.setValues({ analysisFile: undefined });
    }
  };

  const handleAssignCU = (value: number) => {
    form.setValues({
      assignedId: value,
      assignedDate: new Date(),
    });
  };

  const handleSelectClikResultCategory = (value: CuResultCategory) => {
    form.setValues({
      clikResultCategory: value,
      clikResultReviewDate: new Date(),
    });
  };

  const handleSelectInsuranceResult = (value: CuInsuranceResult) => {
    form.setValues({
      insuranceResult: value,
      insuranceResultDate: new Date(),
    });
  };

  const handleSelectAnalysisResult = (value: CuAnalysisResult) => {
    form.setValues({
      analysisResult: value,
      analysisResultDate: new Date(),
      cancelSubmissionReason: null,
    });
  };

  return (
    <>
      <Modal
        opened={opened}
        title={value ? "Edit Credit Underwriting" : "Add Credit Underwriting"}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="xl"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <SimpleGrid cols={2} spacing={20} verticalSpacing={8}>
            <Flex gap={12} direction="column">
              <DatePickerInput
                label="CU Assigned Date"
                placeholder="Select CU Assigned Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                disabled
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("assignedDate")}
              />
              <Select
                withinPortal
                required
                data={assigneesOptions}
                label="CU Assigned"
                placeholder="Select CU Assigned"
                size="m"
                value={
                  form.values.assignedId ? String(form.values.assignedId) : null
                }
                onChange={(value) => handleAssignCU(Number(value))}
              />
              <DatePickerInput
                label="CLIK Result Review Date"
                placeholder="Select CLIK Result Review Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                disabled
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("clikResultReviewDate")}
              />
              <Select
                withinPortal
                required
                data={cuResultCategoryOptions}
                label="CLIK Result Category"
                placeholder="Select CLIK Result Category"
                size="m"
                {...form.getInputProps("clikResultCategory")}
                onChange={(value) =>
                  handleSelectClikResultCategory(value as CuResultCategory)
                }
              />
              <DatePickerInput
                label="LOI Result Date"
                placeholder="Select LOI Result Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                clearable
                required
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("loiResultDate")}
              />
              <Input.Wrapper label="LOI Result" size="m" w="100%">
                <Dropzone
                  maxFiles={1}
                  value={
                    form.values.loiResultFile
                      ? [form.values.loiResultFile]
                      : null
                  }
                  onUploadSuccess={(value) =>
                    handleFileUploaded("loiResultFile", value)
                  }
                  onRemove={() => handleRemoveFile("loiResultFile")}
                />
              </Input.Wrapper>
              <Select
                withinPortal
                required
                data={cuLoiResultCategoryOptions}
                label="LOI Result Category"
                placeholder="Select LOI Result Category"
                size="m"
                {...form.getInputProps("loiResultCategory")}
              />
              <DatePickerInput
                label="Insurance Request Date"
                placeholder="Select Insurance Request Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                clearable
                required
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("insuranceRequestDate")}
              />
              <Select
                withinPortal
                required
                data={cuInsuranceResultOptions}
                label="Insurance Result"
                placeholder="Select Insurance Result"
                size="m"
                {...form.getInputProps("insuranceResult")}
                onChange={(value) =>
                  handleSelectInsuranceResult(value as CuInsuranceResult)
                }
              />
              <DatePickerInput
                label="Insurance Result Date"
                placeholder="Select Insurance Result Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                disabled
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("insuranceResultDate")}
              />
              <NumberInput
                label="Insurance Limit Recommended"
                placeholder="Enter Insurance Limit Recommended"
                required
                size="m"
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                min={0}
                hideControls
                {...form.getInputProps("insuranceLimitApproved")}
              />
            </Flex>
            <Flex gap={12} direction="column">
              <DatePickerInput
                label="Visit Date"
                placeholder="Select Visit Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                clearable
                required
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("visitDate")}
              />
              <DatePickerInput
                label="Document Collection Date"
                placeholder="Select Document Collection Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                clearable
                required
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("documentCollectionStartDate")}
              />
              <DatePickerInput
                label="Document Completed Date"
                placeholder="Select Document Completed Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                clearable
                required
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("documentCompletedDate")}
              />
              <Select
                withinPortal
                required
                data={cuAnalysisResultOptions}
                label="CU Analysis Result"
                placeholder="Select CU Analysis Result"
                size="m"
                {...form.getInputProps("analysisResult")}
                onChange={(value) =>
                  handleSelectAnalysisResult(value as CuAnalysisResult)
                }
              />
              {form.values.analysisResult ===
                CuAnalysisResult.CancelSubmission && (
                <Select
                  withinPortal
                  required
                  data={cuCancelSubmissionOptions}
                  label="CU Cancel Submission Reason"
                  placeholder="Select CU Cancel Submission Reason"
                  size="m"
                  {...form.getInputProps("cancelSubmissionReason")}
                />
              )}
              <DatePickerInput
                label="CU Analysis Result Date"
                placeholder="Select CU Analysis Result Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                disabled
                {...form.getInputProps("analysisResultDate")}
              />
              <Input.Wrapper required label="CU Analysis" size="m" w="100%">
                <Dropzone
                  maxFiles={1}
                  value={
                    form.values.analysisFile ? [form.values.analysisFile] : null
                  }
                  onUploadSuccess={(value) =>
                    handleFileUploaded("analysisFile", value)
                  }
                  onRemove={() => handleRemoveFile("analysisFile")}
                />
              </Input.Wrapper>
              <NumberInput
                label="CU Limit Recommendation"
                placeholder="Enter CU Limit Recommendation"
                required
                size="m"
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                min={0}
                hideControls
                {...form.getInputProps("limitRecommendation")}
              />
              <NumberInput
                label="CU Tenor Recommendation"
                placeholder="Enter CU Tenor Recommendation"
                required
                size="m"
                parser={parseStringToNumber}
                min={0}
                hideControls
                {...form.getInputProps("tenorRecommendation")}
              />
              <NumberInput
                label="CU Pricing Recommendation"
                placeholder="Enter CU Pricing Recommendation"
                required
                size="m"
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                min={0}
                hideControls
                {...form.getInputProps("pricingRecommendation")}
              />
              <Textarea
                label="Notes"
                placeholder="Enter Notes"
                autosize
                size="m"
                minRows={3}
                maxRows={3}
                {...form.getInputProps("notes")}
              />
            </Flex>
          </SimpleGrid>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              save
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default CreditUnderwritingModal;
