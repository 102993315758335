import { SelectItem } from "@mantine/core/lib/Select/types";
import get from "lodash/get";

import {
  GetTransactionHistoryListQuery,
  PaymentMethod,
  PaymentStatus,
  PaymentType,
  TransactionHistoryOrderStatus,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";

export type TransactionHistory =
  GetTransactionHistoryListQuery["transactionHistory"]["data"][0];

export const paymentStatusOptions: SelectItem[] = Object.values(
  PaymentStatus
).map((value) => ({
  value,
  label: toHumanReadable(value),
}));

const orderStatusMapping: Partial<
  Record<TransactionHistoryOrderStatus, string>
> = {
  [TransactionHistoryOrderStatus.Rfq]: "RFQ",
  [TransactionHistoryOrderStatus.PoSubmission]: "PO Submission",
};

export const orderStatusToHumanReadable = (
  status: TransactionHistoryOrderStatus
) => {
  const mapped = get(orderStatusMapping, status);
  return mapped ?? toHumanReadable(status);
};

export const orderStatusOptions: SelectItem[] = Object.values(
  TransactionHistoryOrderStatus
).map((value) => ({
  value,
  label: orderStatusToHumanReadable(value),
}));

const paymentTypeOptionsMapping: Partial<Record<PaymentType, string>> = {
  [PaymentType.Top]: "Term of Payment (TOP)",
  [PaymentType.Cod]: "Cash on Delivery (COD)",
  [PaymentType.Cbd]: "Cash Before Delivery (CBD)",
};

export const paymentTypeOptions: SelectItem[] = Object.values(PaymentType).map(
  (value) => ({
    value,
    label: get(paymentTypeOptionsMapping, value) ?? toHumanReadable(value),
  })
);

export const paymentMethodOptions: SelectItem[] = Object.values(
  PaymentMethod
).map((value) => ({
  value,
  label: toHumanReadable(value, true),
}));
