import { SelectItem } from "@mantine/core/lib/Select/types";
import { sortBy, uniq } from "lodash";

import { CustomerOption } from "../types/customer.ts";

export const getCompanyOptions = (
  companies: CustomerOption[] | null | undefined
): SelectItem[] => {
  return sortBy(
    (companies ?? []).map((company) => ({
      value: company.id.toString(),
      label: company.name,
    })),
    "label"
  );
};

export const getSalesTeamOptions = (
  companies: CustomerOption[] | null | undefined
) => {
  return sortBy(
    uniq(
      companies?.map((company) => company.salesTeamName || "")?.filter(Boolean)
    )
  );
};

export const getSalesNameOptions = (
  companies: CustomerOption[] | null | undefined
) => {
  return sortBy(
    uniq(companies?.map((company) => company.salesName || "")?.filter(Boolean))
  );
};
