import { DataTableSortStatus } from "mantine-datatable";

import {
  CustomerOrderBy,
  LimitRequestOrderBy,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";

export const getLimitRequestApiSort = (
  tableSort: DataTableSortStatus
): LimitRequestOrderBy => {
  const { companyName, salesName, salesTeamName, ...apiSort } =
    getApiOrderBy(tableSort);
  let company: CustomerOrderBy | undefined = undefined;
  if (companyName) {
    company = { name: companyName };
  }
  if (salesName) {
    company = { salesName };
  }
  if (salesTeamName) {
    company = { salesTeamName };
  }
  return {
    ...apiSort,
    company,
  };
};
