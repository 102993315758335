import { Flex } from "@mantine/core";

import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import RestructureReview from "./RestructureReview/RestructureReview";
import WaiveReview from "./WaiveReview/WaiveReview";

export default function ReviewPage() {
  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Review & Approval</Title>

        <RestructureReview />

        <WaiveReview />
      </Flex>
    </Layout>
  );
}
