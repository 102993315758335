import isNil from "lodash/isNil";
import startCase from "lodash/startCase";

export const toHumanReadable = (
  value: string | null | undefined,
  capitalize?: boolean
): string => {
  if (isNil(value)) {
    return "";
  }
  if (!capitalize) {
    return startCase(value.replace("_", " ").toLowerCase());
  }
  return value.replace("_", " ").toUpperCase();
};
