import { useMemo } from "react";
import { Link } from "react-router-dom";

import TableFilesDisplay from "../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { OfferLetter } from "../../../../types/offerLetter.ts";
import { booleanToString } from "../../../../utils/boolean.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface Props {
  offeringLetter: OfferLetter | null | undefined;
}

export const useOfferingLetterCardData = ({ offeringLetter }: Props) => {
  return useMemo(() => {
    return [
      {
        label: "Ol No",
        content: offeringLetter?.olNo,
      },
      {
        label: "OL signing date",
        content: toLocalDate(offeringLetter?.olSignedDate),
      },
      {
        label: "Signed OL",
        content: offeringLetter?.olSigned && (
          <Link
            to={offeringLetter.olSigned.url}
            target="_blank"
            rel="noreferrer"
          >
            Download
          </Link>
        ),
      },
      {
        label: "Signed PKS",
        content:
          offeringLetter?.pksSigned?.length &&
          (offeringLetter.pksSigned.length > 1 ? (
            <TableFilesDisplay files={offeringLetter.pksSigned} />
          ) : (
            <Link
              to={offeringLetter.pksSigned[0].url}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </Link>
          )),
      },
      {
        label: "Signed PG",
        content:
          offeringLetter?.pgSigned?.length &&
          (offeringLetter.pgSigned.length > 1 ? (
            <TableFilesDisplay files={offeringLetter.pgSigned} />
          ) : (
            <Link
              to={offeringLetter.pgSigned[0].url}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </Link>
          )),
      },
      {
        label: "OL expired date",
        content: toLocalDate(offeringLetter?.olExpiredDate),
      },
      {
        label: "OL Status",
        content: toHumanReadable(offeringLetter?.olStatus),
      },
      {
        label: "GIRO Regis",
        content: booleanToString(offeringLetter?.giroRegis),
      },
    ];
  }, [offeringLetter]);
};
