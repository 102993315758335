import { useMemo } from "react";
import { Link } from "react-router-dom";

import { LimitRequestDetail } from "../../../../types/limitRequest.ts";
import { RiskReview } from "../../../../types/riskReview.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice, toPercent } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../utils/user.ts";

interface Props {
  limitRequest:
    | Pick<LimitRequestDetail, "rrAssigned" | "rrAssignedDate">
    | null
    | undefined;
  riskReview: RiskReview | null | undefined;
}

export default function useRiskReviewCardData({
  limitRequest,
  riskReview,
}: Props) {
  return useMemo(() => {
    return [
      {
        label: "RR Assigned Date",
        content: toLocalDate(limitRequest?.rrAssignedDate),
      },
      {
        label: "RR assigned",
        content: getUserDisplayName(limitRequest?.rrAssigned),
      },
      {
        label: "RR analysis result date",
        content: toLocalDate(riskReview?.analysisResultDate),
      },
      {
        label: "RR analysis result",
        content: toHumanReadable(riskReview?.analysisResult),
      },
      {
        label: "RR Limit recommendation",
        content: toCurrencyPrice(riskReview?.limitRecommendation),
      },
      {
        label: "RR Pricing recommendation",
        content: toPercent(riskReview?.pricingRecommendation, "percentage"),
      },
      {
        label: "Risk category",
        content: riskReview?.riskCategory
          ? toHumanReadable(riskReview?.riskCategory)
          : riskReview
          ? "N/A"
          : null,
      },
      {
        label: "RR Tenor Recommendation",
        content: riskReview?.tenorRecommendation,
      },
      {
        label: "RR notes",
        content: riskReview?.notes,
      },
      {
        label: "RR document",
        content: riskReview?.document && (
          <Link to={riskReview.document.url} target="_blank">
            Download
          </Link>
        ),
      },
    ];
  }, [riskReview, limitRequest]);
}
