import {
  Button,
  Flex,
  Input,
  Modal,
  ScrollArea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import Dropzone from "../../../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../../../components/Icons/Calendar";
import {
  CreatePksInput,
  FileResult,
} from "../../../../../graphql/generated.ts";
import { Pks } from "../../../../../types/pks.ts";

export type PksModalSubmitValue = Omit<
  CreatePksInput,
  "id" | "customerId" | "createdAt"
>;

interface Props {
  value?: Pks | null | undefined;
  opened?: boolean;
  onClose: () => void;
  onSubmit: (value: PksModalSubmitValue) => void;
}

const PksModal = ({ value, opened = false, onClose, onSubmit }: Props) => {
  const form = useForm<Partial<Pks>>({
    initialValues: {
      pksDate: value?.pksDate ? new Date(value.pksDate) : undefined,
      pksNo: value?.pksNo,
      pksFile: value?.pksFile,
      expiryDate: value?.expiryDate ? new Date(value.expiryDate) : undefined,
      signedPksFile: value?.signedPksFile,
    },
    validate: {
      pksDate: isNotEmpty("PKS Date is required"),
      pksNo: isNotEmpty("PKS No is required"),
      pksFile: isNotEmpty("PKS File is required"),
      expiryDate: isNotEmpty("PKS Expiry Date is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit({
      ...form.values,
      pksFile: form.values.pksFile?.filePath,
      signedPksFile: form.values.signedPksFile?.filePath ?? null,
    } as PksModalSubmitValue);
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleFileUploaded = (name: keyof Pks, files: FileResult[]) => {
    if (name === "pksFile") {
      form.setValues({ pksFile: files[0] });
    }
    if (name === "signedPksFile") {
      form.setValues({ signedPksFile: files[0] });
    }
  };

  const handleRemoveFile = (name: keyof Pks) => {
    if (name === "pksFile") {
      form.setValues({ pksFile: undefined });
    }
    if (name === "signedPksFile") {
      form.setValues({ signedPksFile: undefined });
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        title={value ? "Edit PKS" : "Add PKS"}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="39em"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Flex gap={12} direction="column">
            <DatePickerInput
              label="PKS Date"
              placeholder="Select PKS Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              popoverProps={{ withinPortal: true, zIndex: 1000 }}
              clearable
              required
              {...form.getInputProps("pksDate")}
            />
            <TextInput
              required
              label="PKS No"
              placeholder="Enter PKS No"
              size="m"
              {...form.getInputProps("pksNo")}
            />
            <Input.Wrapper required label="PKS File" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                maxWidth="unset"
                value={form.values.pksFile ? [form.values.pksFile] : null}
                onUploadSuccess={(value) =>
                  handleFileUploaded("pksFile", value)
                }
                onRemove={() => handleRemoveFile("pksFile")}
              />
            </Input.Wrapper>
            <DatePickerInput
              label="PKS Expiry Date"
              placeholder="Select PKS Expiry Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              popoverProps={{ withinPortal: true, zIndex: 1000 }}
              clearable
              required
              {...form.getInputProps("expiryDate")}
            />
            <Input.Wrapper label="Signed PKS File" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                maxWidth="unset"
                value={
                  form.values.signedPksFile ? [form.values.signedPksFile] : null
                }
                onUploadSuccess={(value) =>
                  handleFileUploaded("signedPksFile", value)
                }
                onRemove={() => handleRemoveFile("signedPksFile")}
              />
            </Input.Wrapper>
          </Flex>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              {value ? "save" : "add"}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default PksModal;
