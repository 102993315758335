import { useQuery } from "urql";

import {
  GetLimitsDocument,
  LimitFilter,
  LimitOrderBy,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const useLimits = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<LimitFilter, LimitOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetLimitsDocument, variables });
};
