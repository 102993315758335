import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  files: {
    color: theme.colors.dusk[0],
    fontSize: rem(14),
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
}));
