import { createStyles, CSSObject } from "@mantine/core";

export const useStyles = createStyles(() => ({
  inviteModalButton: {
    "@media (max-width: 48em)": {
      width: "100%",
    },
  },
  emailWrapper: {
    wordWrap: "break-all" as CSSObject["wordWrap"],
    overflowWrap: "anywhere",
  },
}));
