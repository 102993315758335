import { DataTableSortStatus } from "mantine-datatable";

import {
  CreditUnderwritingOrderBy,
  CustomerOrderBy,
  LimitRequestOrderBy,
  UserOrderBy,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";

export const getCURecommendationApiSort = (
  tableSort: DataTableSortStatus
): LimitRequestOrderBy => {
  const {
    companyName,
    salesName,
    salesTeamName,
    cuLimitRecommendation,
    cuTenorRecommendation,
    cuInsuranceLimit,
    cuResultDate,
    cuAssignedName,
    rrAssignedName,
    ...apiSort
  } = getApiOrderBy(tableSort);
  let company: CustomerOrderBy | undefined = undefined;
  let creditUnderwriting: CreditUnderwritingOrderBy | undefined = undefined;
  let cuAssigned: UserOrderBy | undefined = undefined;
  let rrAssigned: UserOrderBy | undefined = undefined;
  if (companyName) {
    company = { name: companyName };
  }
  if (salesName) {
    company = { salesName };
  }
  if (salesTeamName) {
    company = { salesTeamName };
  }
  if (cuLimitRecommendation) {
    creditUnderwriting = { limitRecommendation: cuLimitRecommendation };
  }
  if (cuTenorRecommendation) {
    creditUnderwriting = { tenorRecommendation: cuTenorRecommendation };
  }
  if (cuInsuranceLimit) {
    creditUnderwriting = { insuranceLimitApproved: cuInsuranceLimit };
  }
  if (cuResultDate) {
    creditUnderwriting = { analysisResultDate: cuResultDate };
  }
  if (cuAssignedName) {
    cuAssigned = { name: cuAssignedName };
  }
  if (rrAssignedName) {
    rrAssigned = { name: rrAssignedName };
  }
  return {
    ...apiSort,
    company,
    creditUnderwriting,
    cuAssigned,
    rrAssigned,
  };
};
