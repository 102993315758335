import { Badge, Button, Flex, Slider, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "../../../components/Table/Table.tsx";
import { TableData, TableRow } from "../../../components/Table/types.ts";
import { AppRoute } from "../../../constants.ts";
import {
  CustomerFreezeStatus,
  LimitStatus,
} from "../../../graphql/generated.ts";
import { Customer } from "../../../types/customer.ts";
import { Limit } from "../../../types/limit.ts";
import {
  calculatePercent,
  toCurrencyPrice,
  toPrice,
} from "../../../utils/number.ts";
import { toHumanReadable } from "../../../utils/string.ts";
import { useStyles } from "../CustomerDatabasePage.styles.ts";

interface Props {
  customers: Customer[] | null | undefined;
  limits: Limit[] | null | undefined;
}

export const useCustomersTableData = ({
  customers,
  limits,
}: Props): TableData => {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "name",
      title: "Name",
      sortable: true,
      width: 250,
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: "Sales Team Name",
      sortable: true,
    },
    {
      accessor: "freezeStatus",
      title: "Customer Status",
      sortable: true,
    },
    {
      accessor: "limits",
      title: "Limits",
    },
    {
      accessor: "actions",
      title: "Actions",
    },
  ];

  const renderFreezeStatusColumn = useCallback((item: Customer) => {
    if (item.freezeStatus === CustomerFreezeStatus.Active) {
      return (
        <Badge color="red" size="sm" radius="xs">
          Freeze
        </Badge>
      );
    }
    return (
      <Badge color="green" size="sm" radius="xs">
        Active
      </Badge>
    );
  }, []);

  const renderLimitStatusColumn = useCallback((item: Limit) => {
    let backgroundColor = "red";
    if (item.status === LimitStatus.Active) {
      backgroundColor = "green";
    } else if (item.status === LimitStatus.Inactive) {
      backgroundColor = "grey";
    }
    return (
      <Badge color={backgroundColor} size="sm" radius="xs">
        {toHumanReadable(item.status)}
      </Badge>
    );
  }, []);

  const renderLimitsColumn = useCallback(
    (item: Customer) => {
      const customerLimits = limits?.filter((l) => l.customerId === item.id);
      if (!customerLimits?.length) {
        return null;
      }
      return (
        <Table
          variant="nested"
          mih={0}
          minWidth="100%"
          noHeader
          highlightOnHover={false}
          columns={[
            {
              accessor: "id",
              hidden: true,
            },
            {
              accessor: "limitName",
              width: 250,
            },
            {
              accessor: "status",
            },
            {
              accessor: "totalPaid",
              width: 500,
            },
          ]}
          rows={customerLimits.map((cl) => ({
            id: cl.id,
            limitName: cl.limitName,
            status: renderLimitStatusColumn(cl),
            totalPaid: (
              <Flex
                gap={20}
                w="100%"
                h="100%"
                justify="flex-end"
                direction="row"
              >
                <Text w="40%">
                  {toCurrencyPrice(cl.totalPaid ?? 0)} /{" "}
                  {toPrice(cl.totalLimit ?? 0)}
                </Text>
                <Slider
                  w="50%"
                  maw="320px"
                  value={cl.totalPaid ?? 0}
                  max={cl.totalLimit}
                  min={0}
                  thumbSize={0.01}
                  disabled
                />
                <Text w="10%">
                  {calculatePercent(cl.totalPaid ?? 0, cl.totalLimit)}%
                </Text>
              </Flex>
            ),
          }))}
        />
      );
    },
    [limits, renderLimitStatusColumn]
  );

  const renderActionsColumn = useCallback(
    (item: Customer) => {
      return (
        <Flex gap={10}>
          <Button variant="outlineBlue" size="xs" disabled={!item.clikDataId}>
            <Link
              className={classes.link}
              to={`${AppRoute.ClikData}/${item.clikDataId}`}
            >
              Buyer Information
            </Link>
          </Button>
          <Button variant="filledBlue" size="xs">
            <Link
              className={classes.link}
              to={`${AppRoute.ActivityManagement}/${item.id}`}
            >
              Customer Activity Details
            </Link>
          </Button>
        </Flex>
      );
    },
    [classes.link]
  );

  const getRow = useCallback(
    (item: Customer): TableRow => {
      return {
        id: item.id,
        name: item.name,
        salesName: item.salesName,
        salesTeamName: item.salesTeamName,
        freezeStatus: renderFreezeStatusColumn(item),
        limits: renderLimitsColumn(item),
        actions: renderActionsColumn(item),
      };
    },
    [renderActionsColumn, renderFreezeStatusColumn, renderLimitsColumn]
  );

  const rows = useMemo(() => {
    return customers?.map(getRow);
  }, [customers, getRow]);

  return [columns, rows];
};
