import { Badge, Button, Flex } from "@mantine/core";
import { useMemo } from "react";

import SvgCheck from "../../../../components/Icons/Check.tsx";
import SvgClose from "../../../../components/Icons/Close.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import {
  LimitRequestStatus,
  OfferLetterStatus,
} from "../../../../graphql/generated.ts";
import { LimitRequest } from "../../../../types/limitRequest.ts";
import { OfferLetter } from "../../../../types/offerLetter.ts";

interface Props {
  limitRequest: Pick<LimitRequest, "requestStatus"> | null | undefined;
  canEdit: boolean;
  offeringLetter: OfferLetter | null | undefined;
  onAddData: () => void;
  onActivateLimit: () => void;
  onRejectLimit: () => void;
}

export const useOfferingLetterCardFooter = ({
  limitRequest,
  canEdit,
  onActivateLimit,
  onRejectLimit,
  offeringLetter,
  onAddData,
}: Props) => {
  const content = useMemo(() => {
    if (!offeringLetter) {
      return (
        <Button
          variant="filledGolden"
          leftIcon={<SvgPlus />}
          w="100%"
          uppercase
          disabled={
            limitRequest?.requestStatus !== LimitRequestStatus.Ol || !canEdit
          }
          onClick={onAddData}
        >
          Add Data
        </Button>
      );
    }

    if (limitRequest?.requestStatus === LimitRequestStatus.Approved) {
      return (
        <Badge size="m" variant="active" w="100%" leftSection={<SvgCheck />}>
          Activated limit
        </Badge>
      );
    }

    if (limitRequest?.requestStatus === LimitRequestStatus.Rejected) {
      return (
        <Badge size="m" variant="updated" w="100%">
          <Flex>
            <SvgClose />
            Rejected limit
          </Flex>
        </Badge>
      );
    }

    if (offeringLetter.olStatus === OfferLetterStatus.RejectedByCustomer) {
      return (
        <Button
          variant="filledGolden"
          leftIcon={<SvgClose />}
          w="100%"
          uppercase
          disabled={!canEdit}
          onClick={onRejectLimit}
        >
          Reject limit
        </Button>
      );
    }

    const disabled =
      !canEdit || offeringLetter.olStatus !== OfferLetterStatus.Active;
    return (
      <Button
        variant="filledGolden"
        leftIcon={<SvgCheck />}
        w="100%"
        uppercase
        disabled={disabled}
        onClick={onActivateLimit}
      >
        Activate limit
      </Button>
    );
  }, [
    offeringLetter,
    limitRequest?.requestStatus,
    canEdit,
    onAddData,
    onRejectLimit,
    onActivateLimit,
  ]);

  return (
    <Flex
      gap={20}
      w="100%"
      h="100%"
      justify="flex-end"
      align="end"
      direction="row"
    >
      {content}
    </Flex>
  );
};
