import { useQuery } from "urql";

import {
  GetCustomerOptionsDocument,
  OrderBy,
} from "../../../graphql/generated";

export const useCompanyOptions = () => {
  return useQuery({
    query: GetCustomerOptionsDocument,
    variables: { filter: { parentId: null }, orderBy: { name: OrderBy.Asc } },
  });
};
