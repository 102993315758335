import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { Invoice } from "../../../../types/invoice.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface Props {
  data: Invoice[] | null | undefined;
}

export const useCustomersNextDueTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "customerName",
      title: "Company Name",
      sortable: true,
      width: "350px",
    },
    {
      accessor: "invoiceNumber",
      title: "Inv No",
      sortable: true,
      width: "250px",
    },
    {
      accessor: "invoiceDueDate",
      title: "Inv Due Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "totalInvoice",
      title: "Total Inv Amount",
      sortable: true,
    },
    {
      accessor: "totalPaid",
      title: "Total Paid Amount",
      sortable: true,
    },
    {
      accessor: "totalAmountDue",
      title: "OSP Amount",
      sortable: true,
    },
    {
      accessor: "paymentStatus",
      title: "Payment Status",
      sortable: true,
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
      width: "180px",
    },
    {
      accessor: "isFirstTransaction",
      title: "Is First Transaction",
      sortable: true,
    },
    {
      accessor: "limitUsed",
      title: "Limit Used",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "hasException",
      title: "Has Exception",
      sortable: true,
      width: "125px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        invoiceNumber: item.invoiceNumber,
        customerName: item.customer.name,
        totalInvoice: toCurrencyPrice(item.totalInvoice),
        invoiceDueDate: toLocalDate(item.invoiceDueDate),
        totalPaid: toCurrencyPrice(item.totalPaid),
        totalAmountDue: toCurrencyPrice(item.totalAmountDue),
        paymentStatus: toHumanReadable(item.paymentStatus),
        salesName: item.salesName,
        isFirstTransaction: item.isFirstTransaction ? "Yes" : "No",
        limitUsed: item.limitUsed,
        hasException: item.hasException ? "Yes" : "No",
      };
    });
  }, [data]);

  return [columns, rows];
};
