import { useMemo } from "react";
import { Link } from "react-router-dom";

import { ClikDataLimitRequest } from "../../../../types/clikData.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface Props {
  limitRequest: ClikDataLimitRequest | null | undefined;
}

export const useLimitRequestCompanyInfoCards = ({ limitRequest }: Props) => {
  const companyInfo = useMemo(() => {
    return [
      {
        label: "Company Clik Result Name",
        content: limitRequest?.company.name,
      },
      {
        label: "Sales Name",
        content: limitRequest?.company.salesName,
      },
      {
        label: "Sales Team",
        content: limitRequest?.company.salesTeamName,
      },
      {
        label: "Business Identity Number",
        content: limitRequest?.bussinessIdentifyNumber,
      },
      {
        label: "Phone Number",
        content: limitRequest?.phone && (
          <Link to={"tel:" + limitRequest?.phone} target="_blank">
            {limitRequest.phone}
          </Link>
        ),
      },
      {
        label: "NPWP company",
        content: limitRequest?.npwpCompany?.url && (
          <Link to={limitRequest.npwpCompany.url} target="_blank">
            Download
          </Link>
        ),
      },
    ];
  }, [limitRequest]);

  const companyAddress = useMemo(() => {
    return [
      {
        label: "NPWP address",
        content: limitRequest?.npwpAddress,
      },
      {
        label: "Sub-district",
        content: limitRequest?.subDistrict,
      },
      {
        label: "District",
        content: limitRequest?.district,
      },
      {
        label: "City",
        content: toHumanReadable(limitRequest?.city.name),
      },
      {
        label: "Postal Code",
        content: limitRequest?.postalCode,
      },
      {
        label: "Country",
        content:
          limitRequest?.country?.name &&
          toHumanReadable(limitRequest.country.name),
      },
    ];
  }, [limitRequest]);

  return [companyInfo, companyAddress];
};
