import { isEmpty, pickBy } from "lodash";

import { LimitRequestFilter } from "../graphql/generated.ts";
import { FiltersValue } from "../pages/ActivityManagementDetail/InvoicesFilterToolbar/types.ts";
import { LimitRequestFiltersValue } from "../types/filter.ts";

export const getFiltersCount = (filter: FiltersValue) =>
  Object.values(filter).filter((prop) => !isEmpty(prop)).length;

export const getLimitRequestApiFilter = (
  value: LimitRequestFiltersValue
): LimitRequestFilter => {
  const companyFilter = pickBy({
    id: value?.companyName && { equals: Number(value.companyName) },
    salesName: value?.salesName && { equals: value.salesName },
    salesTeamName: value?.salesTeamName && { equals: value.salesTeamName },
  });
  const limitDetailFilter = pickBy({
    limitType: value?.limitType && {
      equals: value.limitType,
    },
  });

  return pickBy({
    requestStatus: value?.requestStatus && { equals: value.requestStatus },
    cuAssignedId: value?.cuAssignedId && { equals: value.cuAssignedId },
    rrAssignedId: value?.rrAssignedId && { equals: value.rrAssignedId },
    company: isEmpty(companyFilter) ? undefined : companyFilter,
    limitDetail: isEmpty(limitDetailFilter) ? undefined : limitDetailFilter,
  });
};
