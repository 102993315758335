import { useQuery } from "urql";

import {
  CustomerFilter,
  CustomerOrderBy,
  GetCustomersDocument,
} from "../../../graphql/generated";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const useCustomers = ({
  filter,
  orderBy,
  page,
  pause,
}: ApiHookProps<CustomerFilter, CustomerOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetCustomersDocument, variables, pause });
};
