import { sortBy } from "lodash";

import {
  GetLimitDetailsQuery,
  GetLimitsQuery,
  LimitStatus,
  LimitType,
} from "../graphql/generated";
import { toHumanReadable } from "../utils/string.ts";

export type Limit = GetLimitsQuery["limits"]["data"][0];
export type LimitDetail = GetLimitDetailsQuery["limitDetails"]["data"][0];

export const limitTypeOptions = Object.values(LimitType).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const displayedLimitStatuses = [
  LimitStatus.Active,
  LimitStatus.Expired,
  LimitStatus.Freeze,
  LimitStatus.Inactive,
  LimitStatus.Reject,
];
export const activeLimitStatusOptions = sortBy(
  Object.values(displayedLimitStatuses).map((value) => {
    return { value, label: toHumanReadable(value) };
  }),
  "label"
);
