import { Flex } from "@mantine/core";
import { DatesRangeValue } from "@mantine/dates/lib/types/DatePickerValue";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { omit } from "lodash";
import isNil from "lodash/isNil";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import { useCurrentUserContext } from "../../../../components/Auth/CurrentUserProvider.tsx";
import SvgWarning from "../../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../components/Table/types.ts";
import {
  CreateGiroManagementInput,
  GiroManagementFilter,
  GiroManagementOrderBy,
  OrderBy,
  RolePermission,
  UpdateGiroManagementInput,
} from "../../../../graphql/generated.ts";
import { useCreateGiroManagement } from "../../../../hooks/api/giroManagement/useCreateGiroManagement.ts";
import { useGiroManagementList } from "../../../../hooks/api/giroManagement/useGiroManagementList.ts";
import { useUpdateGiroManagement } from "../../../../hooks/api/giroManagement/useUpdateGiroManagement.ts";
import { useGetOlNumbersList } from "../../../../hooks/api/offerLetter/useGetOlNumbersList.ts";
import { useGetSoNumbersList } from "../../../../hooks/api/transactionHistory/useGetSoNumbersList.ts";
import { useMutationNotificationWrapper } from "../../../../hooks/useMutationNotificationWrapper.tsx";
import { Customer } from "../../../../types/customer.ts";
import {
  GiroManagement,
  GiroManagementModalType,
  RawGiroManagementType,
} from "../../../../types/giroManagement.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { getPagesCount } from "../../../../utils/pagination.ts";
import { hasPermission } from "../../../../utils/user.ts";
import GiroManagementModal from "./GiroManagementModal/GiroManagementModal.tsx";
import GiroManagementToolbar from "./GiroManagementToolbar/GiroManagementToolbar.tsx";
import { useGiroManagementTableData } from "./hooks/useGiroManagementTableData.tsx";
import { getGiroManagementApiFilter, getGiroNumber } from "./utils.ts";

interface Props {
  company: Pick<Customer, "id" | "partnerCode"> | null | undefined;
}

const GiroManagementTable = ({ company }: Props) => {
  const [filter, setFilter] = useState<GiroManagementFilter>({});
  const [orderBy, setOrderBy] = useState<GiroManagementOrderBy>({
    createdAt: OrderBy.Asc,
  });
  const [selectedGiroManagement, setSelectedGiroManagement] =
    useState<GiroManagement | null>();

  const [giroModalOpened, { open: giroModalOpen, close: giroModalClose }] =
    useDisclosure(false);

  const [page, setPage] = useState<number>(1);

  const [{ data, fetching, error }, refresh] = useGiroManagementList({
    filter: { ...filter, customerId: { equals: company?.id } },
    orderBy,
    page,
  });

  const [{ data: olNumbersData }] = useGetOlNumbersList({
    customerId: company?.id,
  });

  const [{ data: soNumbersData }] = useGetSoNumbersList({
    customerId: company?.id,
  });

  const { user: currentUser } = useCurrentUserContext();

  const olNumbersList = (olNumbersData?.offerLetters ?? []).map(
    (item) => item.olNo
  );
  const soNumbersList = (soNumbersData?.transactionHistory?.data ?? [])
    .map((item) => item.saleOrderNumber)
    .filter((item) => !isNil(item)) as string[];

  const giroManagementList: GiroManagement[] | undefined | null =
    data?.giroManagementList?.data;
  const pageCount = getPagesCount(data?.giroManagementList?.total);

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback(
    (key: string, value: string | DatesRangeValue | null) => {
      setFilter(getGiroManagementApiFilter({ [key]: value }));
    },
    []
  );

  const handleGiroModalClose = () => {
    setSelectedGiroManagement(null);
    giroModalClose();
  };

  const [, updateGiroManagement] = useMutationNotificationWrapper(
    useUpdateGiroManagement(),
    {
      success: {
        message: "Giro Management has been updated successfully.",
      },
    }
  );

  const [, createGiroManagement] = useMutationNotificationWrapper(
    useCreateGiroManagement(),
    {
      success: {
        message: "Giro Management has been added successfully.",
      },
    }
  );

  const handleModalOpen = (value: GiroManagement) => {
    setSelectedGiroManagement(value);
    giroModalOpen();
  };

  const handleUpdate = useCallback(
    (id: number, value: RawGiroManagementType) => {
      const input = omit(value, [
        "id",
        "createdAt",
        "updatedAt",
        "customerId",
      ]) as UpdateGiroManagementInput;
      updateGiroManagement({ id, input }).then((result) => {
        if (!result.error) {
          refresh();
        }
      });
    },
    [refresh, updateGiroManagement]
  );

  const handleCreate = useCallback(
    (input: GiroManagementModalType) => {
      createGiroManagement({
        input: {
          ...input,
          number: getGiroNumber(
            input?.type,
            company?.partnerCode,
            data?.giroManagementList?.total
          ),
          customerId: company?.id,
        } as CreateGiroManagementInput,
      }).then((result) => {
        if (!result.error) {
          refresh();
        }
      });
    },
    [createGiroManagement, company, data?.giroManagementList?.total, refresh]
  );

  const handleGiroManagementModalSubmit = (input: GiroManagementModalType) => {
    if (selectedGiroManagement) {
      handleUpdate(selectedGiroManagement.id, {
        ...input,
        customerId: company?.id,
      } as RawGiroManagementType);
    } else {
      handleCreate(input as GiroManagementModalType);
    }
    handleGiroModalClose();
  };

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditBuyerInfoLimitInfoPksLists
  );

  const [columns, rows] = useGiroManagementTableData({
    data: giroManagementList,
    canEdit: hasEditAccess,
    olNumbersList: olNumbersList,
    soNumbersList: soNumbersList,
    onInlineEdit: handleUpdate,
    onOpenEditModal: handleModalOpen,
  });

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  if (!company) {
    return;
  }

  if (error) {
    notifications.clean();
    notifications.show({
      message:
        "Something went wrong while trying to fetch Giro Management Lists data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <>
      <Flex direction="column" gap={16}>
        <SectionTitle variant="bronze">GIRO Management</SectionTitle>
        <GiroManagementToolbar
          canEdit={hasEditAccess}
          onModalOpen={giroModalOpen}
          onFilterChange={handleFilterChange}
        />
        <Table
          columns={columns}
          rows={rows}
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.asc,
          }}
          loading={fetching}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          onSortChange={handleSortChange}
        />
      </Flex>
      {giroModalOpened && (
        <GiroManagementModal
          value={selectedGiroManagement}
          opened={giroModalOpened}
          olNumbersList={olNumbersList}
          soNumbersList={soNumbersList}
          onSubmit={handleGiroManagementModalSubmit}
          onClose={handleGiroModalClose}
        />
      )}
    </>
  );
};

export default GiroManagementTable;
