import { MantineTheme } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const baseSwitch = () => ({
  thumb: {
    cursor: "pointer",
  },
  track: {
    cursor: "pointer",
  },
});

const yellowSwitch = (theme: MantineTheme) => ({
  ...baseSwitch(),
  root: {
    "input:checked+.mantine-Switch-track": {
      background: `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)`,
      borderColor: "transparent",
    },
  },
});

const blueSwitch = (theme: MantineTheme) => ({
  ...baseSwitch(),
  root: {
    "input:checked+.mantine-Switch-track": {
      background: `linear-gradient(357deg, ${theme.colors.dusk[0]} 8.1%, ${theme.colors.dusk[1]} 51.61%, ${theme.colors.dusk[2]} 64.51%, ${theme.colors.dusk[3]} 85.59%)`,
      borderColor: "transparent",
    },
  },
});

export default function switchTheme(): ThemeComponent {
  return {
    variants: {
      yellow: yellowSwitch,
      blue: blueSwitch,
    },
  };
}
