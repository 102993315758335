import { Badge, Button, Flex, Select, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import CPExceptionTableCard from "../../../../components/CPExceptionTableCard/CPExceptionTableCard.tsx";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip.tsx";
import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { boolSelectorOptions } from "../../../../constants.ts";
import {
  PaymentStatus,
  PaymentType,
  TransactionHistoryOrderStatus,
  TransactionHistoryStatus,
  UpdateTransactionHistoryInput,
} from "../../../../graphql/generated.ts";
import {
  orderStatusToHumanReadable,
  TransactionHistory,
} from "../../../../types/transactionHistory.ts";
import { booleanToString, stringToBoolean } from "../../../../utils/boolean.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { ModalType } from "../types.ts";

interface Props {
  data: TransactionHistory[] | null | undefined;
  onApprove: (id: number) => void;
  onReject: (id: number) => void;
  onOpenEditModal: (type: ModalType, value: TransactionHistory) => void;
  onInlineEdit: (id: number, input: UpdateTransactionHistoryInput) => void;
}

export const useTransactionHistoryTableData = ({
  data,
  onApprove,
  onReject,
  onOpenEditModal,
  onInlineEdit,
}: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "orderDate",
      title: "Order Date",
      sortable: true,
    },
    {
      accessor: "quotationNo",
      title: "Quotation No",
      sortable: true,
      width: 300,
    },
    {
      accessor: "saleOrderNumber",
      title: "SO No",
      sortable: true,
      width: 300,
    },
    {
      accessor: "invoiceNumber",
      title: "Invoice No",
      width: 300,
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
      width: 200,
    },
    {
      accessor: "salesTeam",
      title: "Sales Team",
      sortable: true,
      width: 200,
    },
    {
      accessor: "limitUsed",
      title: "Limit Used",
      width: 300,
    },
    {
      accessor: "paymentType",
      title: "Payment Type",
      sortable: true,
    },
    {
      accessor: "paymentMethod",
      title: "Payment Method",
      sortable: true,
    },
    {
      accessor: "orderStatus",
      title: "Order Status",
      sortable: true,
    },
    {
      accessor: "amountTotal",
      title: "Total Order Amount",
      sortable: true,
      width: 200,
    },
    {
      accessor: "totalInvoiced",
      title: "Total Invoice",
      sortable: true,
      width: 200,
    },
    {
      accessor: "outstandingPrincipal",
      title: "OSP",
      sortable: true,
      width: 200,
    },
    {
      accessor: "lateCharge",
      title: "Late Charge",
      sortable: true,
      width: 200,
    },
    {
      accessor: "totalAr",
      title: "Total AR",
      sortable: true,
      width: 200,
    },
    {
      accessor: "totalPaid",
      title: "Total Paid Amount",
      sortable: true,
      width: 200,
    },
    {
      accessor: "dpd",
      title: "DPD",
      sortable: true,
    },
    {
      accessor: "paymentStatus",
      title: "Payment Status",
      sortable: true,
    },
    {
      accessor: "invoiceDueDate",
      title: "Invoice Due Date",
      sortable: true,
    },
    {
      accessor: "hasException",
      title: (
        <Flex align="center">
          <Text>Has Exceptions</Text>
          <InfoTooltip tooltipText="Only COD payment type can be changed." />
        </Flex>
      ),
      sortable: true,
    },
    {
      accessor: "isRestructured",
      title: "Is Restructured",
      sortable: true,
    },
    {
      accessor: "conditionPrecedents",
      title: (
        <Flex align="center">
          <Text>Condition Precedents</Text>
          <InfoTooltip tooltipText="You can add an condition precedent only if a limit exists in the transaction history record." />
        </Flex>
      ),
      sortable: false,
      width: 300,
    },
    {
      accessor: "exceptions",
      title: (
        <Flex align="center">
          <Text>Exceptions</Text>
          <InfoTooltip tooltipText="You can add an exception only if a limit exists in the transaction history record." />
        </Flex>
      ),
      sortable: false,
      width: 300,
    },
    {
      accessor: "approval",
      title: (
        <Flex align="center">
          <Text>Approval</Text>
          <InfoTooltip tooltipText="Only TOP payment type can be approved or rejected" />
        </Flex>
      ),
      width: 400,
    },
  ];

  const handleOpenEditModal = useCallback(
    (type: ModalType, value: TransactionHistory) => {
      onOpenEditModal(type, value);
    },
    [onOpenEditModal]
  );

  const handleInlineEdit = useCallback(
    (id: number, input: UpdateTransactionHistoryInput) => {
      onInlineEdit(id, input);
    },
    [onInlineEdit]
  );

  const getExceptionColumn = useCallback(
    (item: TransactionHistory) => {
      if (item.paymentType === PaymentType.Cod) {
        return (
          <Select
            data={boolSelectorOptions}
            value={item?.hasException?.toString()}
            withinPortal
            zIndex={100}
            size="s"
            onChange={(value) =>
              handleInlineEdit(item.id, {
                hasException: stringToBoolean(value, true),
              })
            }
          />
        );
      }
      return item.hasException ? "True" : "False";
    },
    [handleInlineEdit]
  );

  const renderApproval = useCallback(
    (item: TransactionHistory) => {
      if (item.status === TransactionHistoryStatus.Approved) {
        return (
          <Badge variant="active" w="80%" size="sm">
            {item.approvedBy
              ? `Approved By: ${item.approvedBy?.name}`
              : "Approved"}
          </Badge>
        );
      }
      if (item.status === TransactionHistoryStatus.Rejected) {
        return (
          <Badge variant="updated" w="80%" size="sm">
            Rejected By: {item.rejectedBy?.name}
          </Badge>
        );
      }
      const disabled =
        item.paymentType !== PaymentType.Top ||
        item.orderStatus !== TransactionHistoryOrderStatus.PoSubmission;
      return (
        <Flex gap={10}>
          <Button
            variant="filledBlue"
            disabled={disabled}
            uppercase
            onClick={() => onApprove(item.id)}
          >
            Approve
          </Button>
          <Button
            variant="outlineBlue"
            disabled={disabled}
            uppercase
            onClick={() => onReject(item.id)}
          >
            Reject
          </Button>
        </Flex>
      );
    },
    [onApprove, onReject]
  );

  const renderPaymentStatus = useCallback((item: TransactionHistory) => {
    const paymentStatus = item.invoice?.paymentStatus;
    if (!paymentStatus) {
      return null;
    }
    let backgroundColor = "red";
    if ([PaymentStatus.InPayment, PaymentStatus.Paid].includes(paymentStatus)) {
      backgroundColor = "green";
    }
    if (paymentStatus === PaymentStatus.Partial) {
      backgroundColor = "blue";
    }
    return (
      <Badge color={backgroundColor} size="sm" radius="xs">
        {toHumanReadable(paymentStatus)}
      </Badge>
    );
  }, []);

  const renderLimitUsed = useCallback((item: TransactionHistory) => {
    if (!item.limit?.limitName) {
      return null;
    }
    return item.limit.limitName.replace("Top Up", "General");
  }, []);

  const getRow = useCallback(
    (item: TransactionHistory): TableRow => {
      const conditionPrecedents = item.conditionPrecedents;
      const exceptions = item.exceptions;
      return {
        id: item.id,
        orderDate: toLocalDate(item.orderDate),
        quotationNo: item.quotationNo,
        saleOrderNumber: item.saleOrderNumber,
        invoiceNumber: item.invoice?.invoiceNumber,
        salesName: item.salesName,
        salesTeam: item.salesTeam,
        limitUsed: renderLimitUsed(item),
        paymentType: toHumanReadable(item.paymentType, true),
        paymentMethod: toHumanReadable(item.paymentMethod),
        orderStatus: orderStatusToHumanReadable(item.orderStatus),
        amountTotal: toCurrencyPrice(item.totalOrderAmount),
        totalInvoiced: toCurrencyPrice(item.invoice?.totalInvoice),
        outstandingPrincipal: toCurrencyPrice(item.invoice?.totalAmountDue),
        lateCharge: toCurrencyPrice(item.invoice?.lateCharge),
        totalAr: toCurrencyPrice(item.invoice?.totalAccountReceivable),
        totalPaid: toCurrencyPrice(item.invoice?.totalPaid),
        dpd: item.invoice?.dpd,
        paymentStatus: renderPaymentStatus(item),
        invoiceDueDate: toLocalDate(item.invoice?.invoiceDueDate),
        hasException: getExceptionColumn(item),
        isRestructured: booleanToString(item?.invoice?.isRestructured),
        conditionPrecedents: (
          <CPExceptionTableCard
            data={conditionPrecedents}
            isEdit={!!conditionPrecedents?.length}
            onEdit={() =>
              handleOpenEditModal(ModalType.ConditionPrecedents, item)
            }
          />
        ),
        exceptions: (
          <CPExceptionTableCard
            data={exceptions}
            isEdit={!!exceptions?.length}
            onEdit={() => handleOpenEditModal(ModalType.Exceptions, item)}
          />
        ),
        approval: renderApproval(item),
      };
    },
    [
      renderLimitUsed,
      renderPaymentStatus,
      getExceptionColumn,
      renderApproval,
      handleOpenEditModal,
    ]
  );

  const rows = useMemo(() => {
    return data?.map(getRow);
  }, [data, getRow]);

  return [columns, rows];
};
