import { Button, Flex, Modal, ScrollArea, SimpleGrid } from "@mantine/core";
import { useCallback, useState } from "react";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import { UpdateLimitRequestConditionPrecedentInput } from "../../../../graphql/generated.ts";
import { useCreateLimitRequestConditionPrecedent } from "../../../../hooks/api/limitRequestConditionPrecedent/useCreateLimitRequestConditionPrecedent.ts";
import { useUpdateLimitRequestConditionPrecedent } from "../../../../hooks/api/limitRequestConditionPrecedent/useUpdateLimitRequestConditionPrecedent.ts";
import { useMutationNotificationWrapper } from "../../../../hooks/useMutationNotificationWrapper.tsx";
import { LimitRequestConditionPrecedent } from "../../../../types/limitRequestConditionPrecedent.ts";
import ConditionPrecedentsForm from "./ConditionPrecedentsForm.tsx";
import { useConditionPrecedentsList } from "./hooks/useConditionPrecedencesList.tsx";

interface Props {
  limitRequestId: number;
  data: LimitRequestConditionPrecedent[];
  opened: boolean;
  onClose: () => void;
}

const ConditionPrecedentsModal = ({
  limitRequestId,
  data,
  opened,
  onClose,
}: Props) => {
  const [items, setItems] = useState(data);
  const [selectedItem, setSelectedItem] =
    useState<LimitRequestConditionPrecedent | null>(null);
  const [openForm, setOpen] = useState<boolean>(false);

  const [, updateConditionPrecedent] = useMutationNotificationWrapper(
    useUpdateLimitRequestConditionPrecedent(),
    {
      success: {
        message: "Condition Precedent was successfully updated.",
      },
    }
  );

  const [, createConditionPrecedent] = useMutationNotificationWrapper(
    useCreateLimitRequestConditionPrecedent(),
    {
      success: {
        message: "Condition Precedent was successfully created.",
      },
    }
  );

  const handleSave = useCallback(
    (input: UpdateLimitRequestConditionPrecedentInput, id?: number) => {
      if (id) {
        updateConditionPrecedent({ id, input }).then((res) => {
          const updatedCP = res.data?.updateLimitRequestConditionPrecedent;
          setItems((prevItems) =>
            prevItems.map((item) => (item.id === id ? updatedCP ?? item : item))
          );
        });
        return;
      }
      createConditionPrecedent({
        input: { ...input, limitRequestId },
      }).then((res) => {
        const createdCP = res?.data?.createLimitRequestConditionPrecedent;
        if (!createdCP) {
          return;
        }
        setItems((prevItems) => [...prevItems, createdCP]);
      });
    },
    [createConditionPrecedent, limitRequestId, updateConditionPrecedent]
  );

  const handleCloseForm = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setOpen, setSelectedItem]);

  const handleSubmitForm = useCallback(
    (value: UpdateLimitRequestConditionPrecedentInput, id?: number) => {
      handleSave(value, id);
      handleCloseForm();
    },
    [handleSave, handleCloseForm]
  );

  const handleOpen = () => setOpen(true);

  const handleEditForm = (value: LimitRequestConditionPrecedent) => {
    setSelectedItem(value);
    handleOpen();
  };

  const conditionPrecedentsItems = useConditionPrecedentsList({
    data: items,
    onEdit: handleEditForm,
  });

  return (
    <Modal
      opened={opened}
      title={
        !openForm || selectedItem
          ? "Edit Condition Precedents"
          : "Add Condition Precedents"
      }
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      scrollAreaComponent={ScrollArea.Autosize}
      size="lg"
      onClose={onClose}
    >
      {openForm ? (
        <ConditionPrecedentsForm
          value={selectedItem}
          onClose={handleCloseForm}
          onSubmit={handleSubmitForm}
        />
      ) : (
        <Flex direction="column" gap={20}>
          <Flex direction="column" gap={8}>
            {conditionPrecedentsItems && (
              <SimpleGrid w="100%" cols={2} spacing={8} verticalSpacing={8}>
                {conditionPrecedentsItems.map((item, index) => (
                  <MultiFieldCard key={index} {...item} />
                ))}
              </SimpleGrid>
            )}
            <Button
              variant="outlineBlue"
              leftIcon={<SvgPlus />}
              uppercase
              onClick={handleOpen}
            >
              Add
            </Button>
          </Flex>
          <Flex gap={20} justify="end">
            <Button variant="outlineBlue" uppercase onClick={onClose}>
              cancel
            </Button>
            <Button variant="filledBlue" uppercase onClick={onClose}>
              save
            </Button>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default ConditionPrecedentsModal;
