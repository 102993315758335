import { ActionIcon, Flex, SimpleGrid } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { isNull } from "lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SvgChevronLeft from "../../components/Icons/ChevronLeft.tsx";
import SvgWarning from "../../components/Icons/Warning.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import { useLimitRequest } from "../../hooks/api/limitRequest/useLimitRequest.ts";
import CreditCommitteeCard from "./CreditCommitteeCard/CreditCommitteeCard.tsx";
import CreditUnderwritingCard from "./CreditUnderwritingCard/CreditUnderwritingCard.tsx";
import LimitRequestInfoCard from "./LimitRequestInfoCard.tsx";
import OfferingLetterCard from "./OfferingLetter/OfferingLetterCard.tsx";
import RiskReviewCard from "./RiskReviewCard/RiskReviewCard.tsx";

const LimitRequestDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams() as { id: string };

  const navigate = useNavigate();

  const [{ data: limitRequestResponse, fetching, error }, refreshLimitRequest] =
    useLimitRequest({
      id,
    });
  const limitRequest = limitRequestResponse?.limitRequest;

  const handleBack = () => navigate(-1);

  if (error || isNull(limitRequest)) {
    navigate(AppRoute.ClikData);
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Layout>
      <Preloader loading={fetching} />
      <Flex direction="column" gap={{ base: 24, sm: 20 }}>
        <Flex gap={20}>
          <ActionIcon size="m" variant="outlineGolden" onClick={handleBack}>
            <SvgChevronLeft />
          </ActionIcon>
          <Title>
            {limitRequest?.company?.name
              ? `Limit Requested Details - ${limitRequest.company.name}`
              : "Limit Requested Details"}
          </Title>
        </Flex>
        <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
          <LimitRequestInfoCard
            loading={fetching}
            limitRequest={limitRequest}
          />
        </SimpleGrid>
        <SimpleGrid cols={1} spacing={20} verticalSpacing={8}>
          <CreditUnderwritingCard
            limitRequest={limitRequest}
            onRefreshLimitRequest={refreshLimitRequest}
          />
        </SimpleGrid>
        <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
          <RiskReviewCard
            limitRequest={limitRequest}
            onRefreshLimitRequest={refreshLimitRequest}
          />
          <CreditCommitteeCard
            limitRequest={limitRequest}
            onRefreshLimitRequest={refreshLimitRequest}
          />
          <OfferingLetterCard
            limitRequest={limitRequest}
            onRefreshLimitRequest={refreshLimitRequest}
          />
        </SimpleGrid>
      </Flex>
    </Layout>
  );
};

export default LimitRequestDetailPage;
