import { Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import { WaiveApprovalStatus } from "../../../graphql/generated";
import { Waive } from "../../../types/waive";
import { toLocalDate } from "../../../utils/date.ts";
import { toCurrencyPrice } from "../../../utils/number.ts";
import { toHumanReadable } from "../../../utils/string";
import { getUserDisplayName } from "../../../utils/user.ts";
import ActionIcon from "../../ActionIcon/ActionIcon";
import SvgEdit from "../../Icons/Edit";
import Spoiler from "../../Spoiler/Spoiler.tsx";
import DisableButton from "../../Table/DisableButton.tsx";
import { TableData, TableRow } from "../../Table/types.ts";
import TableFilesDisplay from "../../TableFilesDisplay/TableFilesDisplay.tsx";
import { useStyles } from "../TableWaive.styles.ts";

interface Props {
  data: Waive[] | null | undefined;
  showActionsColumn?: boolean;
  onDownloadFile?: ClickHandler;
  onEdit?: (id: number) => void;
}

type ClickHandler = (id: Waive["id"], fieldName?: keyof Waive) => void;

export function useTableWaiveData({
  data,
  showActionsColumn,
  onEdit,
}: Props): TableData {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "createdAt",
      title: "Created Date",
      sortable: true,
      width: 160,
    },
    {
      accessor: "requestNumber",
      title: "Waive Request Number",
      sortable: true,
      width: 230,
    },
    {
      accessor: "invoiceNumber",
      title: "Inv No",
      sortable: true,
      width: 250,
    },
    {
      accessor: "lateCharge",
      title: "Late Charge",
      sortable: true,
      width: 150,
    },
    {
      accessor: "amount",
      title: "Waive Amount",
      sortable: true,
      width: 150,
    },
    {
      accessor: "requestDocument",
      title: "Request Document",
    },
    {
      accessor: "approvalStatus",
      title: "Approval Status",
      sortable: true,
      width: 150,
    },
    {
      accessor: "approvalDate",
      title: "Approval Date",
      sortable: true,
      width: 150,
    },
    {
      accessor: "collectorAssigned",
      title: "Collector Assigned",
      sortable: true,
      width: 200,
    },
    {
      accessor: "approvalDocument",
      title: "Approval Document",
    },
    {
      accessor: "notes",
      title: "Notes",
      sortable: true,
      width: 200,
    },
    {
      accessor: "actions",
      title: "Actions",
      hidden: !showActionsColumn,
    },
  ];

  const handleEdit = useCallback(
    (id: number) => {
      onEdit && onEdit(id);
    },
    [onEdit]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      const row: TableRow = {
        id: item.id,
        createdAt: toLocalDate(item.createdAt),
        lateCharge: toCurrencyPrice(item.invoice.lateCharge),
        approvalDate: toLocalDate(item.approvalDate),
        collectorAssigned: item.collectorAssigned && (
          <Text className={classes.collectorAssignedWrapper}>
            {getUserDisplayName(item.collectorAssigned)}
          </Text>
        ),
        invoiceNumber: item.invoice.invoiceNumber,
        approvalStatus: toHumanReadable(item.approvalStatus),
        requestNumber: item.requestNumber,
        notes: item.notes && <Spoiler text={item.notes} />,
        amount: toCurrencyPrice(item.amount),
        requestDocument: item.requestDocument && (
          <TableFilesDisplay files={[item.requestDocument]} />
        ),
        approvalDocument: item.approvalDocument && (
          <TableFilesDisplay files={[item.approvalDocument]} />
        ),
        actions:
          item.approvalStatus !== WaiveApprovalStatus.Pending ? (
            <DisableButton
              text={`Editing is not allowed, waive status is ${toHumanReadable(
                item.approvalStatus
              )}`}
            />
          ) : (
            <ActionIcon onClick={() => handleEdit(item.id)}>
              <SvgEdit />
            </ActionIcon>
          ),
      };
      return row;
    });
  }, [data, classes, handleEdit]);

  return [columns, rows];
}
