import pickBy from "lodash/pickBy";

import { CustomerFilter, LimitFilter } from "../../graphql/generated.ts";
import { displayedLimitStatuses } from "../../types/limit.ts";
import { CustomerFilterValue } from "./types.ts";

export const getCustomerApiFilter = (
  value: CustomerFilterValue
): CustomerFilter => {
  const limitStatuses = value.limitStatuses?.length
    ? value.limitStatuses
    : displayedLimitStatuses;
  const limitFilter =
    value.hasLimits || value.limitStatuses?.length
      ? {
          some: { status: { in: limitStatuses } },
        }
      : undefined;
  return pickBy({
    id: value.id?.length && { in: value.id },
    salesName: value.salesName?.length && { in: value.salesName },
    salesTeamName: value.salesTeam?.length && { in: value.salesTeam },
    freezeStatus: value.freezeStatus?.length && { in: value.freezeStatus },
    limits: limitFilter,
  });
};

export const getLimitApiFilter = (value: CustomerFilterValue): LimitFilter => {
  return pickBy({
    status: value.limitStatuses?.length && { in: value.limitStatuses },
  });
};
