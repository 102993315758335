import {
  CaResult,
  CreditApproval as GqlGeneratedCreditApproval,
  Maybe,
  User,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";

export type CreditApproval = Omit<GqlGeneratedCreditApproval, "updatedBy"> & {
  updatedBy?: Maybe<Pick<User, "id" | "name" | "email">>;
};

export const creditApprovalResultOptions = Object.values(CaResult).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);

export const creditApprovalRRResultOptions = Object.values([
  CaResult.ApprovedByRr,
  CaResult.RejectedByRr,
]).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const creditApprovalCCResultOptions = Object.values([
  CaResult.ApprovedByCc,
  CaResult.RejectedByCc,
]).map((value) => {
  return { value, label: toHumanReadable(value) };
});
