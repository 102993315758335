import { Flex } from "@mantine/core";
import { useRef } from "react";

import { Customer } from "../../../types/customer.ts";
import GiroManagementTable from "./GiroManagement/GiroManagementTable.tsx";
import {
  LimitRequestsTable,
  LimitRequestsTableHandle,
} from "./LimitRequestsTable/LimitRequestsTable.tsx";
import LimitTable from "./LimitTable/LimitTable.tsx";
import PksTable from "./PksTable/PksTable.tsx";

interface Props {
  company: Pick<Customer, "id" | "partnerCode"> | null | undefined;
}

export default function LimitInfo({ company }: Props) {
  const limitRequestsTableRef = useRef<LimitRequestsTableHandle>(null);

  return (
    <Flex direction="column" gap={{ base: 16, sm: 24 }}>
      <LimitTable
        companyId={company?.id}
        refreshLimitRequests={() => {
          limitRequestsTableRef.current?.refresh();
        }}
      />
      <LimitRequestsTable companyId={company?.id} ref={limitRequestsTableRef} />
      <PksTable customerId={company?.id} />
      <GiroManagementTable company={company} />
    </Flex>
  );
}
