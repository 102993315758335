import { LimitRequestStatus } from "../../graphql/generated.ts";

export const CUStatusOptions = [
  {
    value: LimitRequestStatus.OngoingCuManagerReview,
    label: "Ongoing CU Manager Review",
  },
  {
    value: LimitRequestStatus.OngoingCu,
    label: "Ongoing CU",
  },
];
