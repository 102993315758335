import {
  PaymentMethod,
  PaymentStatus,
  PaymentType,
  TransactionHistoryOrderStatus,
} from "../../../graphql/generated.ts";

export enum ModalType {
  ConditionPrecedents = "conditionPrecedents",
  Exceptions = "exceptions",
}

export type TransactionHistoryFiltersValue = {
  salesName?: string;
  salesTeam?: string;
  paymentType?: PaymentType;
  paymentStatus?: PaymentStatus;
  orderStatus?: TransactionHistoryOrderStatus;
  paymentMethod?: PaymentMethod;
  hasException?: boolean;
  isRestructured?: boolean;
};
