import { Flex } from "@mantine/core";
import { isEmpty } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getInvoiceApiSort } from "../../../components/Invoice/InvoicesTable/utils.ts";
import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { SortDirection, TableRow } from "../../../components/Table/types.ts";
import { AppRoute } from "../../../constants.ts";
import {
  InvoiceFilter,
  InvoiceOrderBy,
  OrderBy,
} from "../../../graphql/generated";
import { useCompanyOptions } from "../../../hooks/api/customer/useCompanyOptions.ts";
import { useInvoices } from "../../../hooks/api/invoice/useInvoices.ts";
import { Invoice } from "../../../types/invoice.ts";
import { getPagesCount } from "../../../utils/pagination.ts";
import { getInvoiceApiFilter } from "../utils.ts";
import CustomerPayPastDueToolbar from "./CustomerPayPastDueToolbar/CustomerPayPastDueToolbar.tsx";
import { FiltersValue } from "./CustomerPayPastDueToolbar/types.ts";
import { useCustomerPayPastDue } from "./hooks/useCustomerPayPastDue.tsx";

interface CustomerPayPastDueProps {
  salesTeamName: string[];
}

const CustomerPayPastDue = ({ salesTeamName }: CustomerPayPastDueProps) => {
  const navigate = useNavigate();
  const [invoiceFilter, setInvoiceFilter] = useState<InvoiceFilter>();

  const [orderBy, setOrderBy] = useState<InvoiceOrderBy>({
    invoiceDueDate: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);

  const filter = isEmpty(salesTeamName)
    ? invoiceFilter
    : { ...invoiceFilter, salesTeamName: { in: salesTeamName } };

  const [{ data: invoicesData, fetching }] = useInvoices({
    filter,
    orderBy,
    page,
  });

  const [{ data: customersData }] = useCompanyOptions();

  const invoices = invoicesData?.invoices?.data;
  const pageCount = getPagesCount(invoicesData?.invoices?.total);

  const customers = customersData?.customers.data;

  const [columns, rows] = useCustomerPayPastDue({
    data: invoices,
  });

  const handleFilterChange = useCallback((value: FiltersValue) => {
    setInvoiceFilter(getInvoiceApiFilter(value));
  }, []);

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getInvoiceApiSort(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleInvoiceClick = (record: TableRow) => {
    if (!invoices) {
      return;
    }
    const invoice = invoices.find((item: Invoice) => item.id === record.id);
    if (!invoice) {
      return;
    }
    navigate(`${AppRoute.ActivityManagement}/${invoice.customer.id}`);
  };

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle>List of Customers Day Past Due</SectionTitle>
      <CustomerPayPastDueToolbar
        customers={customers}
        onValueChange={handleFilterChange}
      />
      <Table
        loading={fetching}
        minWidth="100%"
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "invoiceDueDate",
          direction: SortDirection.desc,
        }}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onSortChange={handleSortChange}
        onRowClick={handleInvoiceClick}
      />
    </Flex>
  );
};

export default CustomerPayPastDue;
