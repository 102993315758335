import { Badge, Flex, Text } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import ActionIcon from "../../../../components/ActionIcon/ActionIcon.tsx";
import SvgEdit from "../../../../components/Icons/Edit.tsx";
import TableFilesDisplay from "../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { LimitRequestStatus } from "../../../../graphql/generated.ts";
import { CreditApproval } from "../../../../types/creditApproval.ts";
import { LimitRequestDetail } from "../../../../types/limitRequest.ts";
import { LimitRequestConditionPrecedent } from "../../../../types/limitRequestConditionPrecedent.ts";
import { LimitRequestException } from "../../../../types/limitRequestException.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice, toPercent } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { useStyles } from "../CreditCommitteeCard.styles.ts";

interface Props {
  limitRequest:
    | Pick<LimitRequestDetail, "id" | "creditApproval" | "requestStatus">
    | null
    | undefined;
  creditCommittee: Partial<CreditApproval> | null | undefined;
  exceptions: LimitRequestException[] | null | undefined;
  conditionPrecedents: LimitRequestConditionPrecedent[] | null | undefined;
  hasEditAccess: boolean;
  onEditConditionPrecedents: () => void;
  onEditExceptions: () => void;
}

export const useCreditCommitteeCardData = ({
  limitRequest,
  creditCommittee,
  exceptions,
  conditionPrecedents,
  hasEditAccess,
  onEditExceptions,
  onEditConditionPrecedents,
}: Props) => {
  const { classes } = useStyles();

  const canEditCPAndExceptions =
    hasEditAccess &&
    limitRequest?.requestStatus &&
    [LimitRequestStatus.Ol, LimitRequestStatus.Approved].includes(
      limitRequest.requestStatus
    );

  const [showAllConditionPrecedents, setShowAllConditionPrecedents] =
    useState(false);
  const [showAllExceptions, setShowAllExceptions] = useState(false);

  const generateConditionPrecedentCardItem = useCallback(
    (item: LimitRequestConditionPrecedent) => {
      return [
        {
          label: "Name",
          content: item.name,
        },
        {
          label: "Status",
          content: (
            <Badge
              variant={item.completed ? "active" : "inactive"}
              className={classes.badge}
            >
              {item.completed ? "Complete" : "Not Complete"}
            </Badge>
          ),
        },
        {
          label: "Type",
          content: toHumanReadable(item.type),
        },
        {
          label: "Due date",
          content: toLocalDate(item.dueDate),
        },
        {
          label: "Document Submission",
          content:
            item.documents &&
            (item.documents.length > 1 ? (
              <TableFilesDisplay files={item.documents} />
            ) : (
              <Link to={item.documents[0].url} target="_blank">
                Download
              </Link>
            )),
        },
        {
          label: "Notes",
          content: item.notes,
        },
      ];
    },
    [classes.badge]
  );

  const generateExceptionCardItem = useCallback(
    (item: LimitRequestException) => {
      return [
        {
          label: "Name",
          content: item.name,
        },
        {
          label: "Status",
          content: (
            <Badge
              variant={item.completed ? "active" : "inactive"}
              className={classes.badge}
            >
              {item.completed ? "Complete" : "Not Complete"}
            </Badge>
          ),
        },
        {
          label: "Document Submission",
          content:
            item.documents &&
            (item.documents.length > 1 ? (
              <TableFilesDisplay files={item.documents} />
            ) : (
              <Link to={item.documents[0].url} target="_blank">
                Download
              </Link>
            )),
        },
        {
          label: "Notes",
          content: item.notes,
        },
      ];
    },
    [classes.badge]
  );

  return useMemo(() => {
    return [
      {
        label: "CC date",
        content: toLocalDate(creditCommittee?.date),
      },
      {
        label: "Limit approved",
        content: toCurrencyPrice(creditCommittee?.limitRecommendation),
      },
      {
        label: "Tenor approved",
        content: creditCommittee?.tenorRecommendation,
      },
      {
        label: "Pricing approved",
        content: toPercent(
          creditCommittee?.pricingRecommendation,
          "percentage"
        ),
      },
      {
        label: "Insurance limit approved",
        content: toCurrencyPrice(creditCommittee?.insuranceLimitRecommendation),
      },
      {
        label: "Condition precedence",
        content: (
          <Flex className={classes.expand} gap={5}>
            <Text
              onClick={() =>
                setShowAllConditionPrecedents(!showAllConditionPrecedents)
              }
            >
              {showAllConditionPrecedents
                ? `Hide (${conditionPrecedents?.length})`
                : `Show All (${conditionPrecedents?.length})`}
            </Text>
            {canEditCPAndExceptions && (
              <ActionIcon
                size={25}
                spaces={false}
                onClick={onEditConditionPrecedents}
              >
                <SvgEdit />
              </ActionIcon>
            )}
          </Flex>
        ),
        openNestedContent: showAllConditionPrecedents,
        nestedContent: conditionPrecedents
          ? conditionPrecedents.map(generateConditionPrecedentCardItem)
          : undefined,
      },
      {
        label: "Exception",
        content: (
          <Flex className={classes.expand} gap={5}>
            <Text onClick={() => setShowAllExceptions(!showAllExceptions)}>
              {showAllExceptions
                ? `Hide (${exceptions?.length})`
                : `Show All (${exceptions?.length})`}
            </Text>
            {canEditCPAndExceptions && (
              <ActionIcon size={25} spaces={false} onClick={onEditExceptions}>
                <SvgEdit />
              </ActionIcon>
            )}
          </Flex>
        ),
        openNestedContent: showAllExceptions,
        nestedContent: exceptions
          ? exceptions.map(generateExceptionCardItem)
          : undefined,
      },
      {
        label: "Result",
        content: toHumanReadable(creditCommittee?.result),
      },
      {
        label: "Notes",
        content: creditCommittee?.notes,
      },
    ];
  }, [
    creditCommittee,
    conditionPrecedents,
    classes.expand,
    showAllConditionPrecedents,
    canEditCPAndExceptions,
    onEditConditionPrecedents,
    generateConditionPrecedentCardItem,
    exceptions,
    showAllExceptions,
    onEditExceptions,
    generateExceptionCardItem,
  ]);
};
