import {
  Button,
  Flex,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  Textarea,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback, useMemo } from "react";

import { useCurrentUserContext } from "../../../components/Auth/CurrentUserProvider.tsx";
import SvgCalendar from "../../../components/Icons/Calendar.tsx";
import {
  LimitRequestException,
  UpdateCreditApprovalInput,
  UserRole,
} from "../../../graphql/generated.ts";
import {
  CreditApproval,
  creditApprovalCCResultOptions,
  creditApprovalResultOptions,
  creditApprovalRRResultOptions,
} from "../../../types/creditApproval.ts";
import { LimitRequestConditionPrecedent } from "../../../types/limitRequestConditionPrecedent.ts";
import { parseStringToNumber, priceFormatter } from "../../../utils/number.ts";
import ConditionPrecedents from "../ConditionPrecedents/ConditionPrecedents.tsx";
import Exceptions from "../Exceptions/Exceptions.tsx";
import { useStyles } from "./CreditCommitteeModal.styles.ts";

interface Props {
  value: Partial<CreditApproval> | null | undefined;
  limitRequestId: number | null | undefined;
  exceptions: LimitRequestException[] | null | undefined;
  conditionPrecedents: LimitRequestConditionPrecedent[] | null | undefined;
  opened: boolean;
  onClose: () => void;
  onSubmit: (value: UpdateCreditApprovalInput) => void;
  onRefreshConditionPrecedence: () => void;
  onRefreshExceptionsList: () => void;
}

const CreditCommitteeModal = ({
  value,
  limitRequestId,
  exceptions,
  conditionPrecedents,
  opened,
  onClose,
  onSubmit,
  onRefreshConditionPrecedence,
  onRefreshExceptionsList,
}: Props) => {
  const { classes } = useStyles();

  const { user } = useCurrentUserContext();

  const form = useForm({
    initialValues: {
      date: value?.date ? new Date(value?.date) : undefined,
      result: value?.result,
      limitRecommendation:
        value?.limitRecommendation && Number(value.limitRecommendation),
      tenorRecommendation: value?.tenorRecommendation,
      pricingRecommendation:
        value?.pricingRecommendation && Number(value.pricingRecommendation),
      insuranceLimitRecommendation:
        value?.insuranceLimitRecommendation &&
        Number(value.insuranceLimitRecommendation),
      notes: value?.notes,
    },
    validate: {
      date: isNotEmpty("Date is required"),
      result: isNotEmpty("Result is required"),
      limitRecommendation: isNotEmpty("Limit Approved is required"),
      tenorRecommendation: isNotEmpty("Tenor Approved is required"),
      pricingRecommendation: isNotEmpty("Pricing Approved is required"),
      insuranceLimitRecommendation: isNotEmpty(
        "Insurance Limit Approved is required"
      ),
    },
    validateInputOnBlur: true,
  });

  const resultOptions = useMemo(() => {
    if (!user) {
      return [];
    }
    if ([UserRole.SuperAdmin, UserRole.Admin].includes(user.role)) {
      return creditApprovalResultOptions;
    }
    if (
      [
        UserRole.RrManager,
        UserRole.RrOfficer,
        UserRole.RmonOfficer,
        UserRole.RmonManager,
      ].includes(user.role)
    ) {
      return creditApprovalRRResultOptions;
    }
    if (user.role === UserRole.CreditCommittee) {
      return creditApprovalCCResultOptions;
    }
    return [];
  }, [user]);

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit(form.values as UpdateCreditApprovalInput);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      title={value ? "Edit Credit Committee" : "Add Credit Committee"}
      scrollAreaComponent={ScrollArea.Autosize}
      size="auto"
    >
      <Flex direction="column" gap={20} className={classes.contentWrapper}>
        <Flex gap={20}>
          <Flex direction="column" w="50%" gap={12}>
            <DatePickerInput
              label="CC Date"
              placeholder="Select CC date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              popoverProps={{ withinPortal: true, zIndex: 1000 }}
              clearable
              required
              {...form.getInputProps("date")}
            />
            <NumberInput
              required
              label="Limit Approved"
              placeholder="Enter Limit Approved"
              size="m"
              precision={2}
              parser={parseStringToNumber}
              formatter={priceFormatter}
              min={0}
              hideControls
              {...form.getInputProps("limitRecommendation")}
            />
            <NumberInput
              required
              label="Tenor Approved"
              type="number"
              placeholder="Tenor Approved"
              size="m"
              {...form.getInputProps("tenorRecommendation")}
            />
            <NumberInput
              required
              label="Pricing Approved"
              placeholder="Enter Pricing Approved"
              size="m"
              precision={2}
              parser={parseStringToNumber}
              formatter={priceFormatter}
              min={0}
              hideControls
              {...form.getInputProps("pricingRecommendation")}
            />
            <NumberInput
              required
              label="Insurance Limit Approved"
              placeholder="Enter Insurance Limit Approved"
              size="m"
              precision={2}
              parser={parseStringToNumber}
              formatter={priceFormatter}
              min={0}
              hideControls
              {...form.getInputProps("insuranceLimitRecommendation")}
            />
            <Select
              withinPortal
              dropdownPosition="top"
              required
              data={resultOptions}
              label="Result"
              placeholder="Select Result"
              size="m"
              {...form.getInputProps("result")}
            />
            <Textarea
              label="Notes"
              placeholder="Enter Notes"
              autosize
              size="m"
              minRows={3}
              maxRows={3}
              {...form.getInputProps("notes")}
            />
          </Flex>
          <Flex direction="column" w="50%" gap={12}>
            {limitRequestId && (
              <ConditionPrecedents
                limitRequestId={limitRequestId}
                conditionPrecedents={conditionPrecedents}
                onRefreshConditionPrecedents={onRefreshConditionPrecedence}
              />
            )}
            {limitRequestId && (
              <Exceptions
                limitRequestId={limitRequestId}
                exceptions={exceptions}
                onRefreshExceptions={onRefreshExceptionsList}
              />
            )}
          </Flex>
        </Flex>
        <Flex gap={20} justify="flex-end">
          <Button variant="outlineBlue" uppercase onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="filledBlue"
            uppercase
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CreditCommitteeModal;
